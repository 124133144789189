<template>
	<!-- 新增文章专栏 -->
	<div class="container">
		<div class="policyCompilationTitle">电子政策汇编系统</div>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="navbar">
			<el-breadcrumb-item :to="{ path: '/teamManger' }">
				<el-button type="primary" size="mini">返回</el-button>
			</el-breadcrumb-item>
			<el-breadcrumb-item><el-button type="text" size="mini" style="color: #555;font-size: 14px;">{{title}}</el-button></el-breadcrumb-item>
		</el-breadcrumb>
		<el-table :data="permissionsData" stripe="">
			<el-table-column label="操作对象" width="180">{{title}}</el-table-column>
			<el-table-column label="权限">
				<template slot-scope="scope">
					<el-checkbox-group v-model="checked[scope.$index]">
						<el-checkbox v-for="item in scope.row.permissions" :label="item.name" :key="item.id" @change="handleCheckedPermissionsChange(scope.$index,scope.row)">
							{{item.title}}
						</el-checkbox>
					</el-checkbox-group>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="120">
				<template slot-scope="scope">
					<el-checkbox v-model="checkAll[scope.$index]" @change="handleCheckAllChange(scope.$index,scope.row)">全选</el-checkbox>
				</template>
			</el-table-column>
		</el-table>
		<el-button type="primary" size="mini" class="btn" @click="updatePermissions">确定</el-button>
	</div>

</template>
<script>
	export default {
		data() {
			return {
				title: '',
				id: '',
				orgin_id: '',
				checkAll: {},
				checked: {},
				permissionsData: []
			}
		},
		mounted() {
			this.orgin_id = sessionStorage.organizationId;
			this.title = this.$route.query.title;
			this.id = this.$route.query.id;
			this.getPermissionsList();
			this.getrolePermission();
		},
		methods: {
			getPermissionsList() {
				this.$http.get(this.URL.adminUrl.permissions).then(res => {
					this.permissionsData = res.data.data
					for (let key in this.permissionsData) {
						this.$set(this.checked, key, []);
						this.$set(this.checkAll, key, [])
					}
				})
			},
			getrolePermission() {
				this.$http.get(this.URL.adminUrl.managersList + this.orgin_id + '/roles/' + this.id + '/permissions').then(res => {
					this.permissionsData.forEach((item,index)=>{
						this.checked[index]=res.data.data;
						this.checkAll[index] = res.data.data.length === item.permissions.length;
						this.isIndeterminate= res.data.data.length>0&&res.data.data.length<item.permissions.length
					})
				})
			},
			/* 全选 */
			handleCheckAllChange(index, row) {
				const arr = []
				row.permissions.forEach((item) => {
					arr.push(item.name);
				})
				this.checked[index] = this.checkAll[index] ? arr : [];
				this.isIndeterminate = false;
			},
			//单选
			handleCheckedPermissionsChange(index,row) {
				let checkedCount = this.checked[index].length;
				this.checkAll[index] = checkedCount === row.permissions.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < row.permissions.length;

			},
			updatePermissions() {
				const arr = [];
				let result=[];
				this.permissionsData.forEach((item,index)=>{
					 result = arr.concat(this.checked[index])
					
				})
				this.$http.post(this.URL.adminUrl.managersList + this.orgin_id + '/roles/' + this.id + '/permissions', {
					permissions: result
				}).then(res => {
					this.$router.push('/teamManger')
				})
			}
		}
	}
</script>


<style scoped>
	.navbar {
		margin-bottom: 30px;
		padding: 15px;
		border-bottom: 1px solid #ccc
	}

	.btn {
		margin-top: 30px;
	}

	.el-checkbox {
		margin-bottom: 10px;
	}
</style>
